import { Box, Flex, HStack, Heading, Text, VStack, VisuallyHidden, colors } from "@biblioteksentralen/react";
import { getPath } from "@libry-content/common";
import { useClientSideReady } from "../../../utils/hooks/useClientSideReady";
import { useScreenIsSmall } from "../../../utils/hooks/useScreenIsSmall";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../../InternalLinkOverlay";
import { SanityImagePreservingAspectRatio } from "../../images/SanityImagePreservingAspectRatio";
import { areTimeFragmentsUpToIndexZero, useCountDown } from "../useCountDown";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";

type BannerContentPreludeProps = {
  bibliobattles: ResolvedBibliobattlesBannerData;
  smallScreen: boolean;
};

export const BannerContentPrelude = ({ bibliobattles }: BannerContentPreludeProps) => {
  const { t } = useTranslation();
  const clientSideReady = useClientSideReady();
  const smallScreen = useScreenIsSmall();

  const countdownTo = bibliobattles?.startDate
    ? new Date(`${new Date().getFullYear()}-${bibliobattles?.startDate}T00:00:00`)
    : undefined;

  const timeFragments = useCountDown(countdownTo, { smallScreen });

  if (timeFragments.length > 4) console.error("Bibliobattles countdown is not constructed for >= 1 month");
  if (!bibliobattles?.startDate) return null;

  const reversedFragments = timeFragments.slice(0, 4).reverse();

  return (
    <HStack
      zIndex={1}
      justifyContent="space-around"
      width="100%"
      flexWrap="wrap"
      padding={{ base: "2rem 0 3rem", sm: "2rem 0.5rem", md: "2rem 1.2rem", lg: "2rem" }}
      whiteSpace="nowrap"
    >
      <VStack spacing={{ base: "1rem", lg: "1.5rem" }}>
        <VStack spacing="0rem">
          <Text fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}>{t("Bli med på")}</Text>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Heading as="h2" fontSize={{ base: "3xl", sm: "2rem", lg: "2.75rem" }}>
            Bibliobattles
          </Heading>
          <Text
            fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            4. - 10. november
          </Text>
        </VStack>

        {/* TODO: Expose as a link style variant */}
        <InternalLinkOverlay
          href={getPath(bibliobattles)}
          fontSize={{ base: "md", sm: "lg", lg: "xl" }}
          fontWeight="semibold"
          background="#FFE560"
          color={colors.black}
          textShadow="none"
          _hover={{ transform: "scale(1.05)", transition: ".25s" }}
          transition="1s ease"
          borderRadius="lg"
          padding={{ base: "0.375rem 0.875rem", sm: "0.5rem 1rem" }}
          boxShadow="0px 3px 20px 0px rgba(36, 113, 16, 0.68)"
          zIndex={2}
        >
          {t("Les mer")}
        </InternalLinkOverlay>
      </VStack>

      <VStack
        alignItems="flex-start"
        spacing={{ base: "0.25rem", lg: "0.5rem" }}
        marginTop="0.5rem"
        display={{ base: "none", md: "block" }}
      >
        <Text fontSize="sm" fontWeight="600">
          {t("Byggingen starter om")}
          {"..."}
        </Text>
        <VisuallyHidden aria-live="polite" aria-atomic aria-relevant="all">
          {reversedFragments
            .slice(0, 3)
            .map(([amount, unit]) => `${amount} ${unit}`)
            .join(", ")}
        </VisuallyHidden>
        {clientSideReady && (
          <Box
            height={{ base: "4rem", lg: "5rem" }}
            width={{ base: "20rem", lg: "24rem" }}
            position="relative"
            aria-hidden
          >
            <Box
              position="absolute"
              aria-hidden
              width="40%"
              right={0}
              top={0}
              transform="translate(-10%, -80%)"
              filter="drop-shadow(0 .3em 1em black)"
              _groupHover={{ transform: { scale: "1.2" } }} /* FIXME: Doesn't work */
            >
              <SanityImagePreservingAspectRatio
                image={bibliobattles.templateContent?.bannerCharacters}
                resolution={230}
                boxShadow="none"
              />
            </Box>
            <Flex
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              justifyContent="space-around"
              alignItems="center"
              padding="1.5rem 0.5rem"
            >
              {reversedFragments.map(([amount, unit], idx) => {
                if (areTimeFragmentsUpToIndexZero(reversedFragments, idx)) return null;

                return (
                  <VStack key={idx} spacing="0" fontWeight="semibold" minWidth={{ base: "3.75rem", lg: "4.75rem" }}>
                    <Text fontSize={{ base: "1.75rem", lg: "2.5rem" }} lineHeight="1">
                      {amount}
                    </Text>
                    <Text>{unit}</Text>
                  </VStack>
                );
              })}
            </Flex>
          </Box>
        )}
      </VStack>
    </HStack>
  );
};
