import { Box, BoxProps, Button, colors, Heading, Icon, LinkOverlay, VStack } from "@biblioteksentralen/react";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { Countdown } from "./CountDown";
import { ResolvedSommerlesBannerData } from "./sanityQuery";
import { ExternalLink } from "react-feather";

type BannerContentPreludeProps = {
  sommerles: ResolvedSommerlesBannerData;
  smallScreen: boolean;
};

export const BannerContentPrelude = ({ sommerles, smallScreen }: BannerContentPreludeProps) => {
  const { t } = useTranslation();

  if (!sommerles?.templateContent) return null;

  return (
    <VStack
      zIndex={2}
      justifyContent={{ base: "space-around", md: "space-between" }}
      width="100%"
      padding={{ base: "1rem 0", md: "2rem 0" }}
      spacing="1rem"
    >
      <Heading
        as="h2"
        display="flex"
        flexDirection="column"
        alignItems="center"
        filter={`drop-shadow(0 .1em .5em ${colors.black})`}
        fontSize={{ base: ".8rem", md: "1.2rem" }}
        lineHeight={1}
      >
        <GradientText>{t("Vi teller ned til")}</GradientText>
        <GradientText fontSize="2.5em">{t("Sommerles")}</GradientText>
      </Heading>
      <Countdown sommerles={sommerles} smallScreen={smallScreen} />
      <Button
        as={sommerles.separatePage ? InternalLinkOverlay : LinkOverlay}
        href={sommerles?.separatePage ? getPath(sommerles) : sommerles?.templateContent?.url!}
        background="linear-gradient(90deg, #FFDE47, #FF9B05)"
        color="#60318D"
        size={{ base: "md", md: "lg" }}
        border="none"
        rightIcon={sommerles?.separatePage ? undefined : <Icon as={ExternalLink} size="1em" />}
      >
        {t("Les om")} {t("Sommerles")}
      </Button>
    </VStack>
  );
};

const GradientText = (props: BoxProps) => (
  <Box background="linear-gradient(#FFDE47, #FF9B05)" backgroundClip="text" color="transparent" {...props} />
);
